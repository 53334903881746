import React from "react";
import Navbar from "../components/Navbar";
import Board from "../components/Board";
import "../tictactoe.css";
function Home() {
  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h3 className="text-center mb-4">Tic Tac Toe</h3>
        <Board />
      </div>
    </>
  );
}

export default Home;
