
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Dinosaur from './pages/Dinosaur';
import Taixiu from './session8/Taixiu';
import KeoBuaBao from './pages/KeoBuaBao';
import NotFound from './pages/NotFound';



function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/dinosaur' element={<Dinosaur />} />
        <Route path="/taixiu" element={<Taixiu />} />
        <Route path="/keobuabao" element={<KeoBuaBao />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
